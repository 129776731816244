@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Inter", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #3c74a8;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #3c74a8 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9b9b9b;
}

@layer utilities {
  @variants responsive {
    .masonry-3-col {
      column-count: 3;
      column-gap: 1em;
    }
    .masonry-2-col {
      column-count: 2;
      column-gap: 1em;
    }
    .masonry-1-col {
      column-count: 1;
      column-gap: 1em;
    }
  }
}

.break-inside {
  break-inside: avoid;
}

.masonry-layout {
  column-count: 1;
  column-gap: 1em;
}

@media screen and (min-width: 1200px) {
  .masonry-layout {
    column-count: 2;
    column-gap: 1em;
  }
}

@media screen and (min-width: 1500px) {
  .masonry-layout {
    column-count: 3;
    column-gap: 1em;
  }
}

.spinner {
  --radius: 33.6;
  --size: 16.8;
  position: relative;
  width: calc(var(--size) * 1px);
  height: calc(var(--size) * 1px);
}

.spinner div {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #474bff;
  border-radius: 50%;
  transform: rotate(calc(var(--angle) * 1deg))
    translate(0, calc(var(--radius) * 0px));
  animation: spinner-19rk4d 1.5s calc(var(--delay) * 1.2s) infinite ease;
}

.spinner div:nth-child(1) {
  --angle: 45;
  --delay: 0.1;
}

.spinner div:nth-child(2) {
  --angle: 90;
  --delay: 0.2;
}

.spinner div:nth-child(3) {
  --angle: 135;
  --delay: 0.3;
}

.spinner div:nth-child(4) {
  --angle: 180;
  --delay: 0.4;
}

.spinner div:nth-child(5) {
  --angle: 225;
  --delay: 0.5;
}

.spinner div:nth-child(6) {
  --angle: 270;
  --delay: 0.6;
}

.spinner div:nth-child(7) {
  --angle: 315;
  --delay: 0.7;
}

.spinner div:nth-child(8) {
  --angle: 360;
  --delay: 0.8;
}

@keyframes spinner-19rk4d {
  0%,
  30%,
  50%,
  100% {
    transform: rotate(calc(var(--angle) * 1deg))
      translate(0, calc(var(--radius) * 0px)) scale(0);
  }

  40% {
    transform: rotate(calc(var(--angle) * 1deg))
      translate(0, calc(var(--radius) * 1px)) scale(1);
  }
}

.h-29 {
  height: 29rem;
}

.w-custom-15 {
  width: 15.5rem;
}

.custom-menu .ps-menu-button:hover {
  background-color: transparent !important;
  font-weight: 500;
}

.react-tagsinput {
  width: 100%;
}

.font-13 {
  font-size: 13px !important;
}

.group[data-filled-within="true"]
  .group-data-\[filled-within\=true\]\:text-foreground {
  color: white !important;
}

.custom-textarea {
  height: 400px !important;
}

.custom-btn {
  font-size: 13px !important;
  height: 36px !important;
  padding: 0 14px !important;
}

.upgrade-btn {
  font-size: 13px !important;
  height: 36px !important;
}

.react-calendar-heatmap .color-scale-1 {
  fill: #d6e685;
}
.react-calendar-heatmap .color-scale-2 {
  fill: #8cc665;
}
.react-calendar-heatmap .color-scale-3 {
  fill: #44a340;
}
.react-calendar-heatmap .color-scale-4 {
  fill: #1e6823;
}
